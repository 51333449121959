<template>
  <div class="bg-white shadow-sm rounded-lg p-4">
    <div class="row">
      <div v-if="discriminator === 'administrator'" class="col-3 ml-0 p-0">
        <multiselect v-model="oficeFilter" @input="filterData" :options="offices" :multiple="true"
          :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select Offices"
          label="office_name" track-by="id" :select-label="''" :deselect-label="''" @open="openBackgroundOpacity"
          @close="closeBackgroundOpacity">
          <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen">{{
                values
                  .map((item) => {
                    return item.office_name;
                  })
                  .join(", ")
              }}</span></template>
        </multiselect>
      </div>
      <div class="col-3 ml-2 p-0">
        <el-select v-model="typeClient" @change="filterData" placeholder="Type Client" class="w-100">
          <el-option value="commercial" label="Business">Business</el-option>
          <el-option value="personal" label="Personal">Personal</el-option>
        </el-select>
      </div>
      <div class="col-3 ml-2 p-0">
        <el-select v-model="statusClient" @change="filterData" placeholder="Status" class="w-100">
          <el-option value="active" label="Active">Active</el-option>
          <el-option value="inactive" label="Inactive">Inactive</el-option>
          <el-option value="all" label="All">All</el-option>
        </el-select>
      </div>
    </div>

    <el-table v-show="typeClient == 'commercial'" id="exportCommercial"
      :default-sort="{ prop: 'name', order: 'ascending' }" :data="this.tableData.filter(
        (data) =>
          !searchBusiness ||
          data.name.toLowerCase().includes(searchBusiness.toLowerCase()) ||
          data.office.office_name
            .toLowerCase()
            .includes(searchBusiness.toLowerCase())
      )
        " class="w-100">
      <el-table-column label="Client" sortable prop="name"></el-table-column>
      <el-table-column label="Office" sortable prop="office.office_name"></el-table-column>
      <el-table-column label="Contact" :sort-method="sortContact" sortable prop="shareholders[0].name">
        <template slot-scope="scope">
          <div v-if="scope.row.shareholders.length > 0">
            {{ scope.row.shareholders[0].lastName + " " + scope.row.shareholders[0].name }}
          </div>
          <div v-else>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Phone" sortable prop="phone"></el-table-column>
      <el-table-column label="Email" sortable prop="shareholders[0].email">
        <template slot-scope="scope">
          <div v-if="scope.row.shareholders.length > 0">
            {{ scope.row.shareholders[0].email }}
          </div>
          <div v-else>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Status" sortable prop="status">
        <template slot-scope="scope">
          <div v-if="scope.row.status === 1">
            Active
          </div>
          <div v-else>
            Inactive
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Operations" width="250" v-if="exportDataExcel === false">
        <template slot="header" slot-scope="scope">
          <el-input v-model="searchBusiness" placeholder="Search..." class="p-0" :key="scope.row" />
        </template>
        <template slot-scope="scope">
          <el-button-group>
            <el-button size="mini" icon="el-icon-edit" @click="update(scope.row, scope.$index)"></el-button>
            <el-button size="mini" icon="el-icon-search" @click="view(scope.row)"></el-button>
            <router-link target="_blank" :to="`/admin/client/print/${scope.row.uuid}`" class="ml-2">
              <el-button size="mini" icon="el-icon-download">PDF</el-button>
            </router-link>
            <el-button size="mini" icon="el-icon-delete" @click="remove(scope.row, scope.$index)"></el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>

    <el-table v-show="typeClient == 'personal'" id="exportPersonal"
      :default-sort="{ prop: 'lastName', order: 'ascending' }" :data="this.tableDataPersonal.filter((dataPersonal) =>
        !searchPersonal ||
          dataPersonal.name
            .toLowerCase()
            .includes(searchPersonal.toLowerCase()) ||
          dataPersonal.lastName
          ? dataPersonal.lastName
            .toLowerCase()
            .includes(searchPersonal.toLowerCase())
          : true || dataPersonal.email
            ? dataPersonal.email
              .toLowerCase()
              .includes(searchPersonal.toLowerCase())
            : true || dataPersonal.phone
              ? dataPersonal.phone
                .toLowerCase()
                .includes(searchPersonal.toLowerCase())
              : true
      )
        " class="w-100">
      <el-table-column label="Client" sortable prop="lastName">
        <template slot-scope="scope">
          {{ scope.row.lastName + " " + scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column label="Security Social" sortable prop="securitySocial"></el-table-column>
      <el-table-column label="Phone" sortable prop="phone"></el-table-column>
      <el-table-column label="Email" sortable prop="email"></el-table-column>
      <el-table-column label="Operations" width="250" v-if="exportDataExcel === false">
        <template slot="header" slot-scope="scope">
          <el-input v-model="searchPersonal" placeholder="Search..." class="p-0" :key="scope.row" />
        </template>
        <template slot-scope="scope">
          <el-button-group>
            <el-button icon="el-icon-edit" size="mini" @click="updatePersonal(scope.row, scope.$index)"></el-button>
            <el-button icon="el-icon-delete" size="mini" @click="removePersonal(scope.row, scope.$index)"></el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>

    <el-button class="fixed-bottom new-register" type="success" @click="add">Add New Business Client</el-button>
    <el-button class="fixed-bottom new-register" style="margin-left:185px;" type="success" @click="addPersonal">Add New
      Personal Client</el-button>

    <!-- <el-button
      class="fixed-bottom new-register"
      style="margin-left:400px;"
      @click="
        () => {
          exportDataExcel = true;
          exportExcel();
        }
      "
      >Export CSV</el-button
    > -->
    <download-excel class="el-button fixed-bottom new-register el-button--primary fixed-bottom new-register"
      style="margin-left:400px;" :fetch="exportExcel" worksheet="Corporates" name=" corporates.xls">
      Export Excel
    </download-excel>

    <el-drawer title="Create New Client" :visible.sync="component.drawer" direction="rtl" :before-close="handleClose"
      size="91%">
      <div class="pt-3 pl-5 pr-5 pb-5">
        <component-corporate v-on:refresh="load($event)" :data="component.data" :key="component.render" />
      </div>
    </el-drawer>

    <el-drawer title="Client" :visible.sync="componentView.drawer" direction="rtl" size="91%">
      <div class="pt-3 pl-5 pr-5 pb-5">
        <corporate-view :client="componentView.data" />
      </div>
    </el-drawer>

    <el-drawer title="New Personal Client" :visible.sync="component_shareholder.drawer" :before-close="handleClose"
      direction="rtl" size="1000px">
      <div class="pt-3 pl-5 pr-5 pb-5">
        <component-shareholder v-on:refresh="loadPersonal($event)" :data="component_shareholder.data"
          :key="component_shareholder.render" :ss="component_shareholder.ss" />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import corporate from "@/services/api/corporate";
import shareholder from "@/services/api/shareholder";
import office from "@/services/api/office";
import officeUser from "@/services/api/officeUser";
import ComponentCorporate from "@/components/admin/set/Corporate";
import CorporateView from "@/components/admin/set/CorporateView";
import ComponentShareholder from "@/components/admin/Shareholder";
import Multiselect from "vue-multiselect";
import FileSaver from "file-saver";
import downloadExcel from "vue-json-excel";
export default {
  components: {
    ComponentCorporate,
    ComponentShareholder,
    Multiselect,
    CorporateView,
    downloadExcel
  },
  data() {
    return {
      typeClient: "commercial",
      statusClient: "active",
      exportDataExcel: false,
      searchBusiness: "",
      searchPersonal: "",
      data: [],
      tableData: [],
      dataPersonal: [],
      tableDataPersonal: [],
      oficeFilter: [],
      discriminator: "",
      offices: [],
      component: {
        data: null,
        drawer: false,
        render: 0,
      },
      componentView: {
        data: null,
        drawer: false,
        render: 0,
      },
      component_shareholder: {
        data: null,
        drawer: false,
        render: 0,
        ss: null,
      },
      index: null,
      indexPersonal: null,
    };
  },
  mounted() {
    shareholder.getPersonal().then((response) => {
      this.dataPersonal = Object.assign([], response);
      this.tableDataPersonal = response;
      this.filterData();
    });

    switch (this.$store.getters.discriminator) {
      case "administrator":
        this.discriminator = "administrator";
        office.get().then((response) => {
          this.offices = response;
        });
        corporate.get().then((response) => {
          this.data = Object.assign([], response);
          this.tableData = response;
          this.filterData();
        });
        break;
      case "office":
      case "employee": {
        this.discriminator = "office";
        let idOffice = null;
        officeUser.getOffice(this.$store.getters.id).then((response) => {
          this.offices = response.map((item) => {
            idOffice = item.office.id;
            return {
              id: item.office.id,
              office_name: item.office.office_name,
            };
          });
          corporate.getClientsByOffice(idOffice).then((response) => {
            this.data = Object.assign([], response);
            this.tableData = response;
            this.filterData();
          });
        });
        break;
      }
    }
  },
  methods: {
    exportExcel() {
      if (this.typeClient == 'commercial') {
        return this.tableData.map((item, i) => ({
          Client: item.name,
          Office: item.office.office_name,
          Contact:item.shareholders.length >0 ? item.shareholders[0].lastName+" "+item.shareholders[0].name : '',
          Phone:item.phone,
          Email:item.shareholders.length >0 ? item.shareholders[0].email : '',
          Status:item.status === 1 ? 'Active' : 'Inactive'
        }))
      }
      else { return this.tableDataPersonal.map((item, i) => ({
          Client: item.lastName+" "+item.name,
          "Security Social": item.securitySocial,
          Phone:item.phone,
          Email:item.email,
        })) }

    },
    // exportExcel() {
    //   setTimeout(() => {
    //     /* generate workbook object from table */
    //     var xlsxParam = { raw: true }; // parse only content derived, without performing format conversion
    //     var wb = XLSX.utils.table_to_book(
    //       document.querySelector(
    //         this.typeClient == "commercial"
    //           ? "#exportCommercial"
    //           : "#exportPersonal"
    //       ),
    //       xlsxParam
    //     );

    //     /* get binary string as output */
    //     var wbout = XLSX.write(wb, {
    //       bookType: "csv",
    //       bookSST: true,
    //       type: "array",
    //     });
    //     try {
    //       FileSaver.saveAs(
    //         new Blob([wbout], { type: "application / octet-stream" }),
    //         "Clients.csv"
    //       );
    //       this.exportDataExcel = false;
    //     } catch (e) {
    //       if (typeof console !== "undefined") {
    //         console.log(e, wbout);
    //       }
    //     }
    //     return wbout;
    //   }, 500);
    // },
    sortContact(a, b) {
      if (a.shareholders.length == 0 && b.shareholders.length == 0) return 0
      else if (a.shareholders.length > 0 && b.shareholders.length == 0) return 1
      else if (a.shareholders.length == 0 && b.shareholders.length > 0) return -1
      else if (a.shareholders.length > 0 && b.shareholders.length > 0) {
        const nameA = a.shareholders[0].lastName + '' + a.shareholders[0].name
        const nameB = b.shareholders[0].lastName + '' + b.shareholders[0].name
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }
    },
    load(event) {
      this.component.drawer = false;
      let idOffices = this.oficeFilter.map((office) => {
        return office.id;
      });
      if (event.update) {
        let indexUpdate = null;
        this.data.forEach(function (element, index, array) {
          if (element.id == event.data.id) {
            indexUpdate = index;
          }
        });

        this.data.splice(indexUpdate, 1, event.data);
        this.filterData();
      } else {
        this.data.unshift(event.data);
        this.filterData();
      }
    },
    loadPersonal(event) {
      this.component_shareholder.drawer = false;
      if (event.update) {
        if (this.typeClient === "personal") {
          this.tableDataPersonal.splice(this.indexPersonal, 1, event.data);
        }
        let indexUpdate = null;
        this.data.forEach(function (element, index, array) {
          if (element.id == event.data.id) {
            indexUpdate = index;
          }
        });
        this.dataPersonal.splice(indexUpdate, 1, event.data);
        this.filterData();
      } else {
        this.tableDataPersonal.unshift(event.data);
        this.dataPersonal.unshift(event.data);
        this.filterData();
      }
    },
    filterData() {
      let idOffices = this.oficeFilter.map((office) => {
        return office.id;
      });
      if (this.typeClient == 'commercial') {
        this.tableData = Object.assign([], this.data);

        if (idOffices.length > 0) {
          this.tableData = this.tableData.filter(
            (item) => idOffices.indexOf(item.officeId) != -1
          );
        }

        if (this.statusClient === "active") {
          this.tableData = this.tableData.filter(
            (item) => item.status === 1
          );
        } else if (this.statusClient === "all") {
          this.tableData = this.tableData;
        }
        else {
          this.tableData = this.tableData.filter(
            (item) => item.status == 0
          );
        }
      } else {
        this.tableDataPersonal = Object.assign([], this.dataPersonal);

        if (idOffices.length > 0) {
          this.tableDataPersonal = this.tableDataPersonal.filter(
            (item) => idOffices.indexOf(item.officeId) != -1
          );
        }

        if (this.statusClient === "active") {
          this.tableDataPersonal = this.tableDataPersonal.filter(
            (item) => item.active === 1
          );
        } else if (this.statusClient === "all") {
          this.tableDataPersonal = this.tableDataPersonal;
        } else {
          this.tableDataPersonal = this.tableDataPersonal.filter(
            (item) => item.active === 0
          );
        }
      }

    },
    add() {
      this.component.drawer = true;
      this.component.render += 1;
      this.component.data = null;
    },
    addPersonal() {
      this.component_shareholder.drawer = true;
      this.component_shareholder.render += 1;
      this.component_shareholder.data = null;
      setTimeout(() => {
        this.$prompt("Input Security Social Number").then((ss) => {
          this.component_shareholder.ss = ss;
        });
      }, 500);
    },
    update(row, index) {
      this.index = index;
      //
      this.component.drawer = true;
      this.component.render += 1;
      this.component.data = row;
    },
    view(row) {
      this.componentView.drawer = true;
      this.componentView.render += 1;
      this.componentView.data = row;
    },
    updatePersonal(row, index) {
      this.indexPersonal = index;
      //
      this.component_shareholder.drawer = true;
      this.component_shareholder.render += 1;
      this.component_shareholder.data = row;
    },
    assignment(corporate) {
      this.$router.push(`/admin/client/set/${corporate}`);
    },
    remove(row, index) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#67C23A",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          corporate
            .delete(row)
            .then((response) => {
              this.tableData.splice(index, 1);
              this.data.splice(index, 1);
            })
            .catch(() => {
              this.$message({
                message: "Opps... Something wrong",
                type: "error",
                customClass: "message-error",
              });
            });
        }
      });
    },
    removePersonal(row, index) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#67C23A",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          shareholder
            .delete(row)
            .then((response) => {
              this.tableDataPersonal.splice(index, 1);
              this.dataPersonal.splice(index, 1);
            })
            .catch(() => {
              this.$message({
                message: "Opps... Something wrong",
                type: "error",
                customClass: "message-error",
              });
            });
        }
      });
    },
    handleClose(done) {
      this.$confirm("Are you sure you want to close this?")
        .then((_) => {
          this.component_shareholder.ss = null;
          done();
        })
        .catch((_) => { });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
th,
td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
</style>
